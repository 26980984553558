import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import UserHeader from "../components/UserHeader";
import { useDispatch } from "react-redux";
import { clearUserInfo } from "../redux/slices/userReducer";
import logo from "../utils/logo.PNG";

export default function ManagerPersonalAccount() {
  const userInfo = useSelector((state) => state.user.userInfo);
  console.log(userInfo);
  const dispatch = useDispatch();
  function clearAll() {
    dispatch(clearUserInfo());
  }

  return (
    <div className="lk">
      <header className="header-wrapper">
        <div className="container">
          <div className="header header">
            <Link to={"/managerpersonalaccount"}>
              <img className="img-logo " src={logo} alt="" />
            </Link>
            <Link to="/personalareaorder" className="track button">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 22.75H3C2.04 22.75 1.25 21.96 1.25 21V8C1.25 3.58 3.58 1.25 8 1.25H16C20.42 1.25 22.75 3.58 22.75 8V16C22.75 20.42 20.42 22.75 16 22.75ZM8 2.75C4.42 2.75 2.75 4.42 2.75 8V21C2.75 21.14 2.86 21.25 3 21.25H16C19.58 21.25 21.25 19.58 21.25 16V8C21.25 4.42 19.58 2.75 16 2.75H8Z"
                  fill="#292D32"
                />
                <path
                  d="M15.5 12.75H8.5C8.09 12.75 7.75 12.41 7.75 12C7.75 11.59 8.09 11.25 8.5 11.25H15.5C15.91 11.25 16.25 11.59 16.25 12C16.25 12.41 15.91 12.75 15.5 12.75Z"
                  fill="#292D32"
                />
                <path
                  d="M12 16.25C11.59 16.25 11.25 15.91 11.25 15.5V8.5C11.25 8.09 11.59 7.75 12 7.75C12.41 7.75 12.75 8.09 12.75 8.5V15.5C12.75 15.91 12.41 16.25 12 16.25Z"
                  fill="#292D32"
                />
              </svg>
              Новый заказ
            </Link>
            <div className="buttons-wrapper">
              <Link to="/personalarea" className="user">
                <UserHeader />
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div className="line"></div>
      <div className="push60 hidden-xs"></div>
      <div className="push25 visible-xs"></div>
      <section className="main-section">
        <div className="container">
          <div className="main-inner">
            <div className="title">Личный кабинет</div>
            <div className="push60 hidden-xs"></div>
            <div className="push25 visible-xs"></div>
            <ul>
              {userInfo.job === "admin" && (
                <>
                  <li>
                    <Link to="/curs">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M36.6663 37.9165H3.33301C2.64967 37.9165 2.08301 37.3498 2.08301 36.6665C2.08301 35.9832 2.64967 35.4165 3.33301 35.4165H36.6663C37.3497 35.4165 37.9163 35.9832 37.9163 36.6665C37.9163 37.3498 37.3497 37.9165 36.6663 37.9165Z"
                          fill="#292D32"
                        />
                        <path
                          d="M23.75 37.9168H16.25C15.5667 37.9168 15 37.3502 15 36.6668V6.66683C15 3.80016 16.5833 2.0835 19.25 2.0835H20.75C23.4167 2.0835 25 3.80016 25 6.66683V36.6668C25 37.3502 24.4333 37.9168 23.75 37.9168ZM17.5 35.4168H22.5V6.66683C22.5 4.75016 21.6 4.5835 20.75 4.5835H19.25C18.4 4.5835 17.5 4.75016 17.5 6.66683V35.4168Z"
                          fill="#292D32"
                        />
                        <path
                          d="M11.6667 37.9168H5C4.31667 37.9168 3.75 37.3502 3.75 36.6668V16.6668C3.75 13.8002 5.21667 12.0835 7.66667 12.0835H9C11.45 12.0835 12.9167 13.8002 12.9167 16.6668V36.6668C12.9167 37.3502 12.35 37.9168 11.6667 37.9168ZM6.25 35.4168H10.4167V16.6668C10.4167 14.5835 9.5 14.5835 9 14.5835H7.66667C7.16667 14.5835 6.25 14.5835 6.25 16.6668V35.4168Z"
                          fill="#292D32"
                        />
                        <path
                          d="M34.9997 37.9165H28.333C27.6497 37.9165 27.083 37.3498 27.083 36.6665V24.9998C27.083 22.1332 28.5497 20.4165 30.9997 20.4165H32.333C34.783 20.4165 36.2497 22.1332 36.2497 24.9998V36.6665C36.2497 37.3498 35.683 37.9165 34.9997 37.9165ZM29.583 35.4165H33.7497V24.9998C33.7497 22.9165 32.833 22.9165 32.333 22.9165H30.9997C30.4997 22.9165 29.583 22.9165 29.583 24.9998V35.4165Z"
                          fill="#292D32"
                        />
                      </svg>
                      Курс & Категории
                    </Link>
                  </li>

                  <li>
                    <Link to="/paymentmethod">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.4997 15.4165H3.33301C2.64967 15.4165 2.08301 14.8498 2.08301 14.1665C2.08301 13.4832 2.64967 12.9165 3.33301 12.9165H22.4997C23.183 12.9165 23.7497 13.4832 23.7497 14.1665C23.7497 14.8498 23.183 15.4165 22.4997 15.4165Z"
                          fill="#292D32"
                        />
                        <path
                          d="M13.3333 28.75H10C9.31667 28.75 8.75 28.1833 8.75 27.5C8.75 26.8167 9.31667 26.25 10 26.25H13.3333C14.0167 26.25 14.5833 26.8167 14.5833 27.5C14.5833 28.1833 14.0167 28.75 13.3333 28.75Z"
                          fill="#292D32"
                        />
                        <path
                          d="M24.1667 28.75H17.5C16.8167 28.75 16.25 28.1833 16.25 27.5C16.25 26.8167 16.8167 26.25 17.5 26.25H24.1667C24.85 26.25 25.4167 26.8167 25.4167 27.5C25.4167 28.1833 24.85 28.75 24.1667 28.75Z"
                          fill="#292D32"
                        />
                        <path
                          d="M29.2663 35.4168H10.733C4.09967 35.4168 2.08301 33.4168 2.08301 26.8502V13.1502C2.08301 6.5835 4.09967 4.5835 10.733 4.5835H22.4997C23.183 4.5835 23.7497 5.15016 23.7497 5.8335C23.7497 6.51683 23.183 7.0835 22.4997 7.0835H10.733C5.49967 7.0835 4.58301 7.9835 4.58301 13.1502V26.8335C4.58301 32.0002 5.49967 32.9002 10.733 32.9002H29.2497C34.483 32.9002 35.3997 32.0002 35.3997 26.8335V20.0335C35.3997 19.3502 35.9663 18.7835 36.6497 18.7835C37.333 18.7835 37.8997 19.3502 37.8997 20.0335V26.8335C37.9163 33.4168 35.8997 35.4168 29.2663 35.4168Z"
                          fill="#292D32"
                        />
                        <path
                          d="M36.6667 11.6665H27.5C26.8167 11.6665 26.25 11.0998 26.25 10.4165C26.25 9.73317 26.8167 9.1665 27.5 9.1665H36.6667C37.35 9.1665 37.9167 9.73317 37.9167 10.4165C37.9167 11.0998 37.35 11.6665 36.6667 11.6665Z"
                          fill="#292D32"
                        />
                        <path
                          d="M32.083 16.2502C31.3997 16.2502 30.833 15.6835 30.833 15.0002V5.8335C30.833 5.15016 31.3997 4.5835 32.083 4.5835C32.7663 4.5835 33.333 5.15016 33.333 5.8335V15.0002C33.333 15.6835 32.7663 16.2502 32.083 16.2502Z"
                          fill="#292D32"
                        />
                      </svg>
                      Способы оплаты
                    </Link>
                  </li>
                  <li>
                    <Link to="/address">
                      <i className="uil uil-map"></i>
                      Адрес Склада
                    </Link>
                  </li>
                  <li>
                    <Link to="/statistics">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.4997 25.4166C12.1831 25.4166 11.8664 25.2999 11.6164 25.0499C11.1331 24.5666 11.1331 23.7666 11.6164 23.2832L16.9497 17.9499C17.2164 17.6832 17.5664 17.5499 17.9497 17.5832C18.3164 17.6166 18.6497 17.8166 18.8664 18.1332L20.6831 20.8666L26.5997 14.9499C27.0831 14.4666 27.8831 14.4666 28.3664 14.9499C28.8497 15.4332 28.8497 16.2332 28.3664 16.7166L21.3664 23.7166C21.0997 23.9832 20.7497 24.1166 20.3664 24.0832C19.9997 24.0499 19.6664 23.8499 19.4497 23.5332L17.6331 20.7999L13.3831 25.0499C13.1331 25.2999 12.8164 25.4166 12.4997 25.4166Z"
                          fill="#292D32"
                        />
                        <path
                          d="M27.5003 20.4168C26.817 20.4168 26.2503 19.8502 26.2503 19.1668V17.0835H24.167C23.4837 17.0835 22.917 16.5168 22.917 15.8335C22.917 15.1502 23.4837 14.5835 24.167 14.5835H27.5003C28.1837 14.5835 28.7503 15.1502 28.7503 15.8335V19.1668C28.7503 19.8502 28.1837 20.4168 27.5003 20.4168Z"
                          fill="#292D32"
                        />
                        <path
                          d="M24.9997 37.9168H14.9997C5.94967 37.9168 2.08301 34.0502 2.08301 25.0002V15.0002C2.08301 5.95016 5.94967 2.0835 14.9997 2.0835H24.9997C34.0497 2.0835 37.9163 5.95016 37.9163 15.0002V25.0002C37.9163 34.0502 34.0497 37.9168 24.9997 37.9168ZM14.9997 4.5835C7.31634 4.5835 4.58301 7.31683 4.58301 15.0002V25.0002C4.58301 32.6835 7.31634 35.4168 14.9997 35.4168H24.9997C32.683 35.4168 35.4163 32.6835 35.4163 25.0002V15.0002C35.4163 7.31683 32.683 4.5835 24.9997 4.5835H14.9997Z"
                          fill="#292D32"
                        />
                      </svg>
                      Статистика
                    </Link>
                  </li>
                  <li>
                    <Link to="/promocode">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.95 37.9169C7.98333 37.9169 4.7334 35.0336 4.7334 31.4836V28.0835C4.7334 27.4002 5.30007 26.8335 5.9834 26.8335C6.66673 26.8335 7.2334 27.4002 7.2334 28.0835C7.2334 30.1668 9.24999 31.7335 11.95 31.7335C14.65 31.7335 16.6667 30.1668 16.6667 28.0835C16.6667 27.4002 17.2334 26.8335 17.9167 26.8335C18.6 26.8335 19.1667 27.4002 19.1667 28.0835V31.4836C19.1667 35.0336 15.9333 37.9169 11.95 37.9169ZM7.66665 33.1169C8.39998 34.4835 10.05 35.4169 11.95 35.4169C13.85 35.4169 15.5 34.4669 16.2333 33.1169C15.05 33.8335 13.5833 34.2502 11.95 34.2502C10.3167 34.2502 8.84998 33.8335 7.66665 33.1169Z"
                          fill="#292D32"
                        />
                        <path
                          d="M11.95 29.6667C9.21666 29.6667 6.76672 28.4167 5.55005 26.4334C5.01672 25.5667 4.7334 24.55 4.7334 23.5167C4.7334 21.7667 5.50004 20.1334 6.90004 18.9167C9.60005 16.55 14.25 16.5501 16.9667 18.9001C18.3667 20.1334 19.15 21.7667 19.15 23.5167C19.15 24.55 18.8667 25.5667 18.3334 26.4334C17.1334 28.4167 14.6833 29.6667 11.95 29.6667ZM11.95 19.5834C10.65 19.5834 9.45003 20.0167 8.55003 20.8C7.70003 21.5334 7.2334 22.5 7.2334 23.5167C7.2334 24.1 7.38333 24.6334 7.68333 25.1334C8.45 26.4001 10.0833 27.1834 11.95 27.1834C13.8167 27.1834 15.45 26.4001 16.2 25.1501C16.5 24.6668 16.65 24.1167 16.65 23.5334C16.65 22.5167 16.1834 21.55 15.3334 20.8C14.45 20.0167 13.25 19.5834 11.95 19.5834Z"
                          fill="#292D32"
                        />
                        <path
                          d="M11.95 34.2501C7.83333 34.2501 4.7334 31.6001 4.7334 28.1001V23.5168C4.7334 19.9668 7.96666 17.0835 11.95 17.0835C13.8333 17.0835 15.6334 17.7335 16.9834 18.9002C18.3834 20.1335 19.1667 21.7668 19.1667 23.5168V28.1001C19.1667 31.6001 16.0667 34.2501 11.95 34.2501ZM11.95 19.5835C9.34999 19.5835 7.2334 21.3501 7.2334 23.5168V28.1001C7.2334 30.1835 9.24999 31.7501 11.95 31.7501C14.65 31.7501 16.6667 30.1835 16.6667 28.1001V23.5168C16.6667 22.5001 16.2001 21.5334 15.3501 20.7834C14.4501 20.0168 13.25 19.5835 11.95 19.5835Z"
                          fill="#292D32"
                        />
                        <path
                          d="M31.7335 24.6663C29.2168 24.6663 27.0835 22.7996 26.8835 20.3996C26.7502 19.0163 27.2502 17.6663 28.2502 16.683C29.0835 15.8163 30.2668 15.333 31.5168 15.333H35.0002C36.6502 15.383 37.9168 16.6829 37.9168 18.2829V21.7164C37.9168 23.3164 36.6502 24.6163 35.0502 24.6663H31.7335ZM34.9501 17.833H31.5335C30.9501 17.833 30.4169 18.0497 30.0335 18.4497C29.5502 18.9163 29.3168 19.5496 29.3835 20.183C29.4668 21.283 30.5335 22.1663 31.7335 22.1663H35.0002C35.2168 22.1663 35.4168 21.9664 35.4168 21.7164V18.2829C35.4168 18.0329 35.2168 17.8497 34.9501 17.833Z"
                          fill="#292D32"
                        />
                        <path
                          d="M26.6674 35.4168H22.5007C21.8174 35.4168 21.2507 34.8502 21.2507 34.1668C21.2507 33.4835 21.8174 32.9168 22.5007 32.9168H26.6674C30.9674 32.9168 33.7507 30.1335 33.7507 25.8335V24.6668H31.734C29.2174 24.6668 27.0841 22.8001 26.8841 20.4001C26.7507 19.0168 27.2508 17.6668 28.2508 16.6835C29.0841 15.8168 30.2674 15.3335 31.5174 15.3335H33.734V14.1668C33.734 10.2668 31.4508 7.58346 27.7508 7.15013C27.3508 7.08346 27.0007 7.0835 26.6507 7.0835H11.6507C11.2507 7.0835 10.8674 7.11681 10.484 7.16681C6.81736 7.63348 4.56738 10.3002 4.56738 14.1668V17.5002C4.56738 18.1835 4.00072 18.7502 3.31738 18.7502C2.63405 18.7502 2.06738 18.1835 2.06738 17.5002V14.1668C2.06738 9.0335 5.23411 5.31684 10.1508 4.70017C10.6008 4.63351 11.1174 4.5835 11.6507 4.5835H26.6507C27.0507 4.5835 27.5674 4.60016 28.1007 4.68349C33.0174 5.25016 36.234 8.9835 36.234 14.1668V16.5835C36.234 17.2669 35.6674 17.8335 34.984 17.8335H31.5174C30.934 17.8335 30.4007 18.0502 30.0174 18.4502C29.5341 18.9168 29.3007 19.5501 29.3674 20.1835C29.4507 21.2835 30.5174 22.1668 31.7174 22.1668H35.0007C35.6841 22.1668 36.2507 22.7335 36.2507 23.4168V25.8335C36.2507 31.5668 32.4007 35.4168 26.6674 35.4168Z"
                          fill="#292D32"
                        />
                      </svg>
                      Промо
                    </Link>
                  </li>
                  <li>
                    <Link to="/gift">
                      <i class="uil uil-gift"></i>
                      Подарки
                    </Link>
                  </li>
                  <div className="separator"></div>
                </>
              )}

              {userInfo.job === "productmanager" || userInfo.job === "admin" ? (
                <li>
                  <Link
                    to={`${
                      userInfo.job === "admin"
                        ? "/requestlist"
                        : "/personalareanew"
                    }`}
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.9997 37.9168H14.9997C5.94967 37.9168 2.08301 34.0502 2.08301 25.0002V15.0002C2.08301 5.95016 5.94967 2.0835 14.9997 2.0835H24.9997C34.0497 2.0835 37.9163 5.95016 37.9163 15.0002V25.0002C37.9163 34.0502 34.0497 37.9168 24.9997 37.9168ZM14.9997 4.5835C7.31634 4.5835 4.58301 7.31683 4.58301 15.0002V25.0002C4.58301 32.6835 7.31634 35.4168 14.9997 35.4168H24.9997C32.683 35.4168 35.4163 32.6835 35.4163 25.0002V15.0002C35.4163 7.31683 32.683 4.5835 24.9997 4.5835H14.9997Z"
                        fill="#292D32"
                      />
                      <path
                        d="M17.6334 25.9668C17.3001 25.9668 16.9834 25.8335 16.7501 25.6002L12.0334 20.8835C11.5501 20.4002 11.5501 19.6002 12.0334 19.1168C12.5167 18.6335 13.3167 18.6335 13.8001 19.1168L17.6334 22.9502L26.2001 14.3835C26.6834 13.9002 27.4834 13.9002 27.9667 14.3835C28.4501 14.8668 28.4501 15.6668 27.9667 16.1502L18.5167 25.6002C18.2834 25.8335 17.9667 25.9668 17.6334 25.9668Z"
                        fill="#292D32"
                      />
                    </svg>
                    {userInfo.job === "admin" ? "Заявки" : "Оформление"}
                  </Link>
                </li>
              ) : null}
              <li>
                <Link to="/personalareacheckpay">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.6663 24.5841H3.33301C2.64967 24.5841 2.08301 24.0175 2.08301 23.3341V12.7008C2.08301 12.1675 2.41632 11.7008 2.89966 11.5174C3.39966 11.3341 3.94969 11.4842 4.28302 11.8842C5.29969 13.1008 6.88302 13.7841 8.44969 13.7341C9.78302 13.7008 11.0163 13.2008 11.9496 12.3175C12.383 11.9508 12.733 11.5175 12.9997 11.0341C13.5163 10.1508 13.7663 9.18412 13.7497 8.20079C13.7163 6.66745 13.0497 5.26746 11.9164 4.25079C11.5164 3.90079 11.383 3.35076 11.5663 2.86743C11.7497 2.3841 12.2164 2.05078 12.733 2.05078H24.9997C25.683 2.05078 26.2497 2.61745 26.2497 3.30078V19.9674C26.2497 22.5341 24.1997 24.5841 21.6663 24.5841ZM4.58301 22.0841H21.6663C22.8163 22.0841 23.7497 21.1508 23.7497 20.0008V4.58415H15.3163C15.8997 5.66748 16.2163 6.88417 16.2497 8.15084C16.283 9.60084 15.9163 11.0341 15.183 12.2841C14.783 13.0008 14.233 13.6842 13.633 14.1842C12.2997 15.4508 10.4663 16.2008 8.51632 16.2508C7.11632 16.3008 5.76636 15.9508 4.59969 15.3175V22.0841H4.58301Z"
                      fill="#292D32"
                    />
                    <path
                      d="M31.6663 34.583H29.9997C29.3163 34.583 28.7497 34.0163 28.7497 33.333C28.7497 32.183 27.8163 31.2497 26.6663 31.2497C25.5163 31.2497 24.583 32.183 24.583 33.333C24.583 34.0163 24.0163 34.583 23.333 34.583H16.6663C15.983 34.583 15.4163 34.0163 15.4163 33.333C15.4163 32.183 14.483 31.2497 13.333 31.2497C12.183 31.2497 11.2497 32.183 11.2497 33.333C11.2497 34.0163 10.683 34.583 9.99967 34.583H8.33301C4.88301 34.583 2.08301 31.783 2.08301 28.333V23.333C2.08301 22.6497 2.64967 22.083 3.33301 22.083H21.6663C22.8163 22.083 23.7497 21.1497 23.7497 19.9997V8.33301C23.7497 7.64967 24.3163 7.08301 24.9997 7.08301H28.0664C29.7164 7.08301 31.233 7.96637 32.0496 9.3997L34.8997 14.383C35.1163 14.7663 35.1163 15.2497 34.8997 15.633C34.683 16.0163 34.2663 16.2497 33.8163 16.2497H31.6663C31.433 16.2497 31.2497 16.433 31.2497 16.6663V21.6663C31.2497 21.8997 31.433 22.083 31.6663 22.083H36.6663C37.3497 22.083 37.9163 22.6497 37.9163 23.333V28.333C37.9163 31.783 35.1163 34.583 31.6663 34.583ZM31.083 32.083H31.6663C33.733 32.083 35.4163 30.3997 35.4163 28.333V24.583H31.6663C30.0663 24.583 28.7497 23.2663 28.7497 21.6663V16.6663C28.7497 15.0663 30.0497 13.7497 31.6663 13.7497L29.883 10.633C29.5163 9.98302 28.8164 9.58301 28.0664 9.58301H26.2497V19.9997C26.2497 22.533 24.1997 24.583 21.6663 24.583H4.58301V28.333C4.58301 30.3997 6.26634 32.083 8.33301 32.083H8.9163C9.4663 30.1663 11.233 28.7497 13.333 28.7497C15.433 28.7497 17.1997 30.1663 17.7497 32.083H22.2663C22.8163 30.1663 24.583 28.7497 26.683 28.7497C28.783 28.7497 30.533 30.1663 31.083 32.083Z"
                      fill="#292D32"
                    />
                    <path
                      d="M13.3333 37.9157C10.8 37.9157 8.75 35.8657 8.75 33.3324C8.75 30.799 10.8 28.749 13.3333 28.749C15.8667 28.749 17.9167 30.799 17.9167 33.3324C17.9167 35.8657 15.8667 37.9157 13.3333 37.9157ZM13.3333 31.249C12.1833 31.249 11.25 32.1824 11.25 33.3324C11.25 34.4824 12.1833 35.4157 13.3333 35.4157C14.4833 35.4157 15.4167 34.4824 15.4167 33.3324C15.4167 32.1824 14.4833 31.249 13.3333 31.249Z"
                      fill="#292D32"
                    />
                    <path
                      d="M26.6673 37.9157C24.134 37.9157 22.084 35.8657 22.084 33.3324C22.084 30.799 24.134 28.749 26.6673 28.749C29.2007 28.749 31.2507 30.799 31.2507 33.3324C31.2507 35.8657 29.2007 37.9157 26.6673 37.9157ZM26.6673 31.249C25.5173 31.249 24.584 32.1824 24.584 33.3324C24.584 34.4824 25.5173 35.4157 26.6673 35.4157C27.8173 35.4157 28.7507 34.4824 28.7507 33.3324C28.7507 32.1824 27.8173 31.249 26.6673 31.249Z"
                      fill="#292D32"
                    />
                    <path
                      d="M36.6657 24.5838H31.6657C30.0657 24.5838 28.749 23.2672 28.749 21.6672V16.6672C28.749 15.0672 30.0657 13.7505 31.6657 13.7505H33.8157C34.2657 13.7505 34.6824 13.9838 34.899 14.3838L37.7491 19.3838C37.8491 19.5672 37.9157 19.7838 37.9157 20.0005V23.3338C37.9157 24.0172 37.349 24.5838 36.6657 24.5838ZM31.6657 16.2505C31.4324 16.2505 31.249 16.4338 31.249 16.6672V21.6672C31.249 21.9005 31.4324 22.0838 31.6657 22.0838H35.4157V20.3338L33.0823 16.2505H31.6657Z"
                      fill="#292D32"
                    />
                    <path
                      d="M8.31602 16.2493C6.01603 16.2493 3.84936 15.2493 2.3827 13.4993C2.14936 13.2493 1.89939 12.8993 1.68273 12.566C0.899392 11.3826 0.466059 9.98266 0.432725 8.51599C0.366059 6.08266 1.39941 3.79931 3.26608 2.24931C4.68274 1.08264 6.38266 0.449349 8.18266 0.416016C10.1493 0.432682 12.1161 1.08269 13.5827 2.39935C15.2494 3.86602 16.2161 5.91604 16.2661 8.14937C16.2994 9.59937 15.9327 11.0327 15.1994 12.2827C14.7994 12.9993 14.2494 13.6827 13.6494 14.1827C12.316 15.4494 10.4827 16.1993 8.5327 16.2493C8.44937 16.2493 8.38269 16.2493 8.31602 16.2493ZM8.31602 2.91602C8.28269 2.91602 8.24936 2.91602 8.21603 2.91602C6.99936 2.93268 5.83266 3.3827 4.84933 4.1827C3.58266 5.2327 2.88271 6.79936 2.91604 8.44936C2.94938 9.44936 3.23273 10.3994 3.76606 11.1827C3.91606 11.4161 4.06602 11.6326 4.24936 11.8326C5.31602 13.0993 6.89939 13.766 8.44939 13.7327C9.78272 13.6993 11.016 13.1994 11.9493 12.316C12.3827 11.9494 12.7327 11.516 12.9994 11.0327C13.516 10.1493 13.766 9.18265 13.7494 8.19932C13.716 6.66599 13.0494 5.266 11.9161 4.24933C10.9161 3.39933 9.64936 2.91602 8.31602 2.91602Z"
                      fill="#292D32"
                    />
                    <path
                      d="M7.41614 11.2503C7.09947 11.2503 6.79944 11.1336 6.54944 10.9002L4.86609 9.30031C4.36609 8.81698 4.34949 8.03365 4.83282 7.53365C5.31616 7.03365 6.09949 7.01695 6.59949 7.50028L7.41614 8.28357L10.0328 5.7503C10.5328 5.26697 11.3161 5.28357 11.7995 5.78357C12.2828 6.28357 12.2661 7.067 11.7661 7.55033L8.28273 10.9169C8.03273 11.1336 7.71614 11.2503 7.41614 11.2503Z"
                      fill="#292D32"
                    />
                  </svg>
                  Заказы
                </Link>
              </li>
              <li>
                <Link to="/depot">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.0003 30.8333C16.1003 30.8333 12.917 27.65 12.917 23.75C12.917 23.0667 13.4837 22.5 14.167 22.5C14.8503 22.5 15.417 23.0667 15.417 23.75C15.417 26.2833 17.467 28.3333 20.0003 28.3333C22.5337 28.3333 24.5837 26.2833 24.5837 23.75C24.5837 23.0667 25.1503 22.5 25.8337 22.5C26.517 22.5 27.0837 23.0667 27.0837 23.75C27.0837 27.65 23.9003 30.8333 20.0003 30.8333Z"
                      fill="#292D32"
                    />
                    <path
                      d="M8.64915 10.6332C8.33249 10.6332 7.99915 10.4999 7.76582 10.2666C7.28249 9.78324 7.28249 8.98324 7.76582 8.4999L13.8158 2.4499C14.2992 1.96657 15.0992 1.96657 15.5825 2.4499C16.0658 2.93324 16.0658 3.73324 15.5825 4.21657L9.53249 10.2666C9.28249 10.4999 8.96582 10.6332 8.64915 10.6332Z"
                      fill="#292D32"
                    />
                    <path
                      d="M31.3505 10.6332C31.0339 10.6332 30.7172 10.5166 30.4672 10.2666L24.4172 4.21657C23.9339 3.73324 23.9339 2.93324 24.4172 2.4499C24.9005 1.96657 25.7005 1.96657 26.1839 2.4499L32.2339 8.4999C32.7172 8.98324 32.7172 9.78324 32.2339 10.2666C32.0005 10.4999 31.6672 10.6332 31.3505 10.6332Z"
                      fill="#292D32"
                    />
                    <path
                      d="M33.683 17.6667C33.5663 17.6667 33.4497 17.6667 33.333 17.6667H32.9497H6.66634C5.49967 17.6833 4.16634 17.6833 3.19967 16.7167C2.43301 15.9667 2.08301 14.8 2.08301 13.0833C2.08301 8.5 5.43301 8.5 7.03301 8.5H32.9663C34.5663 8.5 37.9163 8.5 37.9163 13.0833C37.9163 14.8167 37.5663 15.9667 36.7997 16.7167C35.933 17.5833 34.7663 17.6667 33.683 17.6667ZM7.03301 15.1667H33.3497C34.0997 15.1833 34.7997 15.1833 35.033 14.95C35.1497 14.8333 35.3997 14.4333 35.3997 13.0833C35.3997 11.2 34.933 11 32.9497 11H7.03301C5.04967 11 4.58301 11.2 4.58301 13.0833C4.58301 14.4333 4.84967 14.8333 4.94967 14.95C5.18301 15.1667 5.89967 15.1667 6.63301 15.1667H7.03301Z"
                      fill="#292D32"
                    />
                    <path
                      d="M24.8166 37.9172H14.7666C8.79992 37.9172 7.46658 34.3672 6.94992 31.2839L4.59992 16.8672C4.48325 16.1839 4.94992 15.5506 5.63325 15.4339C6.29992 15.3172 6.94992 15.7839 7.06658 16.4672L9.41658 30.8672C9.89992 33.8172 10.8999 35.4172 14.7666 35.4172H24.8166C29.0999 35.4172 29.5833 33.9172 30.1333 31.0172L32.9333 16.4339C33.0666 15.7506 33.7166 15.3006 34.3999 15.4506C35.0833 15.5839 35.5166 16.2339 35.3832 16.9172L32.5832 31.5006C31.9332 34.8839 30.8499 37.9172 24.8166 37.9172Z"
                      fill="#292D32"
                    />
                  </svg>
                  Склад РФ
                </Link>
              </li>
              {userInfo.job === "admin" && (
                <>
                  <li>
                    <Link to="/scanning">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.333 34.5835C9.36634 34.5835 2.08301 27.3002 2.08301 18.3335C2.08301 9.36683 9.36634 2.0835 18.333 2.0835C27.2997 2.0835 34.583 9.36683 34.583 18.3335C34.583 27.3002 27.2997 34.5835 18.333 34.5835ZM18.333 4.5835C10.7497 4.5835 4.58301 10.7502 4.58301 18.3335C4.58301 25.9168 10.7497 32.0835 18.333 32.0835C25.9163 32.0835 32.083 25.9168 32.083 18.3335C32.083 10.7502 25.9163 4.5835 18.333 4.5835Z"
                          fill="#292D32"
                        />
                        <path
                          d="M33.6001 37.9833C33.4668 37.9833 33.3335 37.9667 33.2168 37.95C32.4335 37.85 31.0168 37.3167 30.2168 34.9333C29.8001 33.6833 29.9501 32.4333 30.6335 31.4833C31.3168 30.5333 32.4668 30 33.7835 30C35.4835 30 36.8168 30.65 37.4168 31.8C38.0168 32.95 37.8501 34.4167 36.9001 35.8333C35.7168 37.6167 34.4335 37.9833 33.6001 37.9833ZM32.6001 34.15C32.8835 35.0167 33.2835 35.45 33.5501 35.4833C33.8168 35.5167 34.3168 35.2 34.8335 34.45C35.3168 33.7333 35.3501 33.2167 35.2335 32.9833C35.1168 32.75 34.6501 32.5 33.7835 32.5C33.2668 32.5 32.8835 32.6667 32.6668 32.95C32.4668 33.2333 32.4335 33.6667 32.6001 34.15Z"
                          fill="#292D32"
                        />
                      </svg>
                      Приемка
                    </Link>
                  </li>
                  <li>
                    <Link to="/completed">
                      <i class="uil uil-parcel"></i>Доставлено
                    </Link>
                  </li>
                </>
              )}
              <li>
                <Link to="/" onClick={clearAll}>
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.16043 29.3162C8.96309 30.1475 10.5684 30.2318 11.3711 29.5041C12.4413 28.7765 12.4413 27.5139 11.6386 26.6826L7.09021 21.9986H28.7622C30.0999 21.9986 30.9026 21.1046 30.9026 19.9987C30.9026 18.8951 30.0999 17.9988 28.7622 17.9988H7.09021L11.6386 13.3171C12.4413 12.4859 12.4413 11.2209 11.3711 10.4956C10.5684 9.76796 8.96309 9.8523 8.16043 10.6836L0.401333 18.6831C-0.133778 19.4373 -0.133778 20.5625 0.401333 21.3167L8.16043 29.3162ZM28.7625 3.99976C27.6922 3.99976 26.622 3.10584 26.622 1.99988C26.622 0.896331 27.6922 0 28.7625 0H35.4513C37.8593 0 39.9998 1.79025 39.9998 3.99976V36.0002C39.9998 38.2073 37.8593 40 35.4513 40H28.7625C27.6922 40 26.622 39.1037 26.622 38.0001C26.622 36.8942 27.6922 36.0002 28.7625 36.0002H35.4513V3.99976H28.7625Z"
                      fill="black"
                    />
                  </svg>
                  Выход
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
