import React from "react";
import { useSelector } from "react-redux";

export default function UserHeader() {
  const userInfo = useSelector((state) => state.user.userInfo);

  return (
    <>
      {userInfo.name || "Укажите имя"}{" "}
      {userInfo.surname ? userInfo.surname[0].toUpperCase() + "." : ""}
      {userInfo.lastname ? userInfo.lastname[0].toUpperCase() + "." : ""}
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0003 21.2502C14.717 21.2502 10.417 16.9502 10.417 11.6668C10.417 6.3835 14.717 2.0835 20.0003 2.0835C25.2837 2.0835 29.5837 6.3835 29.5837 11.6668C29.5837 16.9502 25.2837 21.2502 20.0003 21.2502ZM20.0003 4.5835C16.1003 4.5835 12.917 7.76683 12.917 11.6668C12.917 15.5668 16.1003 18.7502 20.0003 18.7502C23.9003 18.7502 27.0837 15.5668 27.0837 11.6668C27.0837 7.76683 23.9003 4.5835 20.0003 4.5835Z"
          fill="#292D32"
        />
        <path
          d="M34.3169 37.9167C33.6336 37.9167 33.0669 37.35 33.0669 36.6667C33.0669 30.9167 27.2003 26.25 20.0003 26.25C12.8003 26.25 6.93359 30.9167 6.93359 36.6667C6.93359 37.35 6.36693 37.9167 5.68359 37.9167C5.00026 37.9167 4.43359 37.35 4.43359 36.6667C4.43359 29.55 11.4169 23.75 20.0003 23.75C28.5836 23.75 35.5669 29.55 35.5669 36.6667C35.5669 37.35 35.0002 37.9167 34.3169 37.9167Z"
          fill="#292D32"
        />
      </svg>
    </>
  );
}
